export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый'
        },
        color: 'green'},
    {
        num: 3,
        label: {
            en: 'Paid',
            et: 'Tasutud',
            ru: 'Оплачен'
        },
        color: 'dark-blue'},
    {
        num: 5,
        label: {
            en: 'Paid part.',
            et: 'Os. tasutud',
            ru: 'Опл. част.'
        },
        color: 'blue'
    }

    // {num: 1, label: 'Uus', color: 'green'},
    // {num: 3, label: 'Tasutud', color: 'dark-blue'},
    // {num: 5, label: 'Os. tasutud', color: 'blue'},
    // {num: 1, label: 'Новый', color: 'green'},
    // {num: 3, label: 'Оплачен', color: 'dark-blue'},
    // {num: 5, label: 'Опл. част.', color: 'blue'},
]


// export default {
//     en: [
//         {num: 1, label: 'New', color: 'green'},
//         {num: 3, label: 'Paid', color: 'dark-blue'},
//         {num: 5, label: 'Paid part.', color: 'blue'},
//     ],
//     et: [
//         {num: 1, label: 'Uus', color: 'green'},
//         {num: 3, label: 'Tasutud', color: 'dark-blue'},
//         {num: 5, label: 'Os. tasutud', color: 'blue'},
//     ],
//     ru: [
//         {num: 1, label: 'Новый', color: 'green'},
//         {num: 3, label: 'Оплачен', color: 'dark-blue'},
//         {num: 5, label: 'Опл. част.', color: 'blue'},
//     ]
// }